<template>
  <div class="modal">
    <div class="modal-header">
      Users & groups
    </div>
    <div class="modal-content">
      <ModalLoading :class="{'fade-in': is_loading === true, 'fade-out': is_loading !== true}"/>
      <div class="admin-group-component" v-for="(group, group_id) in groups" :key="group_id">
        <h2>{{group.name}}</h2>
        <CopyLink :path="`register?g=${group_id}`" />
        <div class="admin-user-component" v-for="(user, index) in group.users" :key="index">
          {{user.username}}
          <button @click="deleteUser(user, group_id, index)">Remove</button>
          <button @click="resetPassword(user)">Get password reset link</button>
        </div>
      </div>
      <div class="add-new-group">
        <h3>Add new group</h3>
        <hr />
        <label for="new_group_name">Group name</label>
        <input id="new_group_name" type="text" v-model="new_group.name">
        <button @click="createGroup">Create</button>
      </div>
    </div>
  </div>
</template>


<script>

import ModalLoading from '@root/components/ModalLoading';
import DataService from '@root/services/Dataservice';
import LoadingService from '@root/generic/LoadingService';
import NotificationService from '@root/generic/NotificationService';
import CopyLink from '@root/components/CopyLink';

export default {
  name: 'AdminUsers',
  components: {
    ModalLoading,
    CopyLink
  },
  data() {
    return {
      is_loading: false,
      groups: [],
      new_group: {}
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init() {
      this.fetchGroupsAndUsers();
    },
    fetchGroupsAndUsers () {
      this.is_loading = true;
      DataService.get('admin/users')
      .then((result) => {
        this.groups = result;
        this.is_loading = false;
      })
      .catch(() => {
        this.is_loading = false;
      })
    },
    deleteUser (user, group_id, index) {
      if (window.confirm(`Deleting user ${user.username}?`)) {
        LoadingService.showLoading();
        DataService.delete('admin/users/' + user.user_id)
        .then(() => {
          this.groups[group_id].users.splice(index, 1);
        })
        .finally(() => {
          LoadingService.hideLoading();
        });
      }
    },
    createGroup () {
      LoadingService.showLoading();
      DataService.post('admin/groups', this.new_group)
      .then(() => {
        LoadingService.hideLoading();
        this.fetchGroupsAndUsers();
      })
      .catch(() => {
        LoadingService.hideLoading();
      })
    },
    resetPassword (user) {
      LoadingService.showLoading();
      DataService.post(`admin/users/${user.user_id}/reset/password`)
      .then((verification_id) => {
        LoadingService.hideLoading();
        console.log('Verificaiton ID', verification_id);
        this.copyLinkToClipboard(user.user_id, verification_id);
        this.fetchGroupsAndUsers();
      })
      .catch(() => {
        LoadingService.hideLoading();
      })
    },
    copyLinkToClipboard (user_id, verification_id) {
      if (navigator.clipboard.writeText(`${window.location.origin}/passreset?uid=${user_id}&tkn=${verification_id}`)) {
        NotificationService.add('success', 'URL copied to clipboard');
      } else {
        NotificationService.add('danger', 'Could not copy link');
      }
    }
  }
}

</script>