<template>
  <div class="modal">
    <div class="modal-header">
     Top 10
    </div>
    <div class="modal-content">
      <ScoreBoard :filter_all="true" :items="topten_data" @refresh="fetchTopTens"/>
    </div>
  </div>
</template>


<script>

import ScoreBoard from '@root/components/ScoreBoard';
import DataService from '@root/services/Dataservice';
import UserService from '@root/services/UserService';

export default {
  name: 'TopTenBoard',
  components: {
    ScoreBoard
  },
  data () {
    return {
      topten_data: {}
    }
  },
  mounted () {
    this.fetchTopTens();
  },
  methods : {
    fetchTopTens () {
      DataService.get('stats/top10').then((result) => {
        let headers = [{
            title: '#',
            key: 'rank'
          },
          {
            title: 'Username',
            key: 'username'
          },
          {
            title: 'Points',
            key: 'points'
          }];
        let data = [];
        const my_username = UserService.getUsername();

        for(const k in result) {
          let obj = {
            rank: parseInt(k, 10) + 1,
            username: result[k].username,
            points: result[k].user_score,
          };
          if (my_username === obj.username) {
            obj.highlight = true;
          }
          if (UserService.isInSameGroup(result[k])) {
            obj.in_same_group = true;
          }
          data.push(obj);
        }
        this.topten_data = {data, headers};
      }).catch(() => {
        this.topten_data = {data: "Nodata"}
      })
    }
  }
}

</script>