
'use strict';

// import Vue from 'vue';
import NotificationService from '@root/generic/NotificationService';
import UserService from '@root/services/UserService';

function qs(object) {
    return JSON.stringify(object);
}

let has_shown_expired_sess_notification = false;

let _endpoint = window._oks = "http://localhost:3333/";

if (process.env.NODE_ENV == 'production') {
  _endpoint = window._oks = "https://oks-api.clickndrive.ee/";
}

// const non_refreshable_401s = [
//   "auth/me"
// ];

const no_notification_endpoints = [
  "auth/me"
];

let _token = null;
// let httpSpan = null;
let _headers = [];
export default {
    token(token) {
        _token = token;
    },

    getToken() {
        return _token;
    },

    appendDefaultHeaders(xhr, type) {
        if (process.env.VUE_APP_DEV_TOKEN != null) {
            xhr.setRequestHeader("Authorization", 'Bearer ' + process.env.VUE_APP_DEV_TOKEN);
        } else if (localStorage.getItem("__t") != null) {
            xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem("__t"));
        }
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Accept', 'application/json');
        // get request doesn't need any content-type header, since it wont send any data
        // also if accessing to CDN files as countries.json or timezone.json, this is not an accepted header
        if (type !== 'get') {
            xhr.setRequestHeader('Content-Type', 'application/json; charset=urf-8');
        }
    },

    addHeader(key, value) {
        _headers.push([key, value]);
    },

    handleError () {
      NotificationService.enable();
      NotificationService.add('danger', 'Something wrong with network. Refresh page.', 10000);
    },

    handle(request, resolve, reject, options) {

      const {
          xhr
      } = request;
      if (xhr.readyState > 3) {
          const content_type = xhr.getResponseHeader('Content-Type');
          try {
              // resolve images / svgs instantly
              if (content_type.indexOf('image') !== -1) {
                  resolve(xhr.responseText);
              } else {
                  const response = JSON.parse(xhr.responseText);
                  const status = xhr.status;
                  // console.log('status', status);
                  // console.log('response', response);
                  if (status >= 200 && status < 400) {
                      if (response.result != null) {
                          if (response.msg != null) {
                              if (options.notification === true || options.notification === 'success') {
                                  if (status >= 300) {
                                      // warnings are with status 300+
                                      NotificationService.add('warning', response.msg);
                                  } else {
                                      NotificationService.add('success', response.msg);
                                  }
                              }
                          }
                          NotificationService.enable();
                          return resolve(response.result, response.msg);
                      } else if (response.errors_list != null && response.errors_list.length > 0) {

                          let messages = [];
                          for (const error of response.errors_list) {

                              // dont show same error twice
                              if (messages.indexOf(error.msg) === -1) {
                                  NotificationService.add('danger', error.msg);
                                  messages.push(error.msg);
                              }
                          }
                          NotificationService.enable();
                          return reject(response);
                      }
                      resolve(response);
                  } else if (status === 401 && UserService.wasAuthed()) {
                    // Will occur in cases - where user was logged in, but after resuming - the session was expired and should automatically redirect to login
                    if (has_shown_expired_sess_notification === false) {
                      has_shown_expired_sess_notification = true;
                      window.confirm('Your session has expired. Please log back in');
                      window.location.reload();
                    }
                  } else {
                      if (response.msg != null) {
                          if (options.notification === true || options.notification === 'error') {
                              NotificationService.add('danger', response.msg);
                          }
                      }
                      if (response.error != null) {
                          reject({
                              message: response.error,
                              code: xhr.status
                          });
                      } else {
                          reject({
                              message: response,
                              code: xhr.status
                          });
                      }
                  }
              }
              NotificationService.enable();
          } catch (e) {
            // console.log('dataservice failure', e)
              const fatal_msg = 'System failure. Please contact system admin';
              NotificationService.add('danger', fatal_msg);
              reject({
                  message: fatal_msg,
                  code: xhr.status
              });
              throw new Error('Response not in JSON: ', xhr.responseText, xhr.status, xhr);
          }
      }
      NotificationService.enable();
    },

    get(url, options) {
      for (let k in no_notification_endpoints) {
        if (url.indexOf(no_notification_endpoints[k]) >= 0) {
          NotificationService.disable();
          break;
        }
      }
        options = this.validateOptions(options);
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            // enable using 3rd party API, as google for locations etc
            // as only set our values if its self request
            if (url.indexOf('http') === -1) {
                url = _endpoint + url;
            }
            xhr.open('GET', url);
            this.appendDefaultHeaders(xhr, 'get', options);
            this.applyHeaders(xhr, _headers);
            xhr.withCredentials = options.withCredentials;
            xhr.onload = () => {
                const request = {
                    xhr
                };
                this.handle(request, resolve, reject, options);
            };
            xhr.onerror = this.handleError;
            xhr.send();
        });
    },

    post(url, data = {}, options) {
      for (let k in no_notification_endpoints) {
        if (url.indexOf(no_notification_endpoints[k]) >= 0) {
          NotificationService.disable();
          break;
        }
      }
        options = this.validateOptions(options);
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            // enable using 3rd party API, as google for locations etc
            // as only set our values if its self request
            if (url.indexOf('http') === -1) {
                url = _endpoint + url;
            }
            xhr.open('POST', url);
            this.appendDefaultHeaders(xhr, 'post', options);
            this.applyHeaders(xhr, _headers);
            xhr.withCredentials = options.withCredentials;
            xhr.onload = () => {
                const request = {
                    xhr
                };
                this.handle(request, resolve, reject, options);
            };
            xhr.onerror = this.handleError;
            xhr.send(qs(data));
        });
    },

    put(url, data = {}, options) {
      for (let k in no_notification_endpoints) {
        if (url.indexOf(no_notification_endpoints[k]) >= 0) {
          NotificationService.disable();
          break;
        }
      }
        options = this.validateOptions(options);
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            // enable using 3rd party API, as google for locations etc
            // as only set our values if its self request
            if (url.indexOf('http') === -1) {
                url = _endpoint + url;
            }
            xhr.open('PUT', url);
            this.appendDefaultHeaders(xhr, 'put', options);
            this.applyHeaders(xhr, _headers);
            xhr.withCredentials = options.withCredentials;
            xhr.onload = () => {
                const request = {
                    xhr
                };
                this.handle(request, resolve, reject, options);
            };
            xhr.onerror = this.handleError;
            xhr.send(qs(data));
        });
    },

    delete(url, data = {}, options) {
      for (let k in no_notification_endpoints) {
        if (url.indexOf(no_notification_endpoints[k]) >= 0) {
          NotificationService.disable();
          break;
        }
      }
        options = this.validateOptions(options);
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            // enable using 3rd party API, as google for locations etc
            // as only set our values if its self request
            if (url.indexOf('http') === -1) {
                url = _endpoint + url;
            }
            xhr.open('DELETE', url);
            this.appendDefaultHeaders(xhr, 'delete', options);
            this.applyHeaders(xhr, _headers);
            xhr.withCredentials = options.withCredentials;
            xhr.onload = () => {
                const request = {
                    xhr
                };
                this.handle(request, resolve, reject, options);
            };
            xhr.onerror = this.handleError;
            xhr.send(qs(data));
        });
    },

    applyHeaders(xhr, headers) {
        if (headers.length > 0) {
            for (let hdr of headers) {
                xhr.setRequestHeader(hdr[0], hdr[1]);
            }
        }
    },

    validateOptions(options = null) {
        // default options
        const defaults = {
            // show notification on response
            notification: true,
            // set withCredentials true / false
            withCredentials: true,
            // default auth is always true
            auth: true,
            // default force writes to eu is true
            force_eu: true
        };
        if (options == null) {
            return defaults;
        }
        for (let key in defaults) {
            if (options[key] == null) {
                options[key] = defaults[key];
            }
        }
        return options;
    }
};
