<template>
  <div class="modal">
    <div class="modal-header">
     Bonuses
    </div>
    <div class="modal-content">
      <ScoreBoard :items="boarddata" class="text-align-left" :refreshable="false"/>
    </div>
  </div>
</template>


<script>

import DataService from '@root/services/Dataservice';
import UserService from '@root/services/UserService';
// import LoadingService from '@root/generic/LoadingService';
// import NotificationService from '../../generic/NotificationService';
import ScoreBoard from '@root/components/ScoreBoard';

export default {
  name: 'BonusBetsResults',
  components: {
    ScoreBoard
  },
  data () {
    return {
      boarddata: []
    }
  },
  mounted () {
    this.fetchBonuses();
  },
  methods : {
    fetchBonuses() {
      DataService.get('finals/users')
      .then((res) => {
        // console.log('res', res);
        let headers = [
          {
            title: 'Username',
            key: 'username',
            extra_classes: ['text-align-left', 'flex-1-1']
          },
          {
            title: '1st finalist',
            key: 'finalist_1',
            extra_classes: ['text-align-center', 'flex-1-1']
          },
          {
            title: '2nd finalist',
            key: 'finalist_2',
            extra_classes: ['text-align-center', 'flex-1-1']
          },
          {
            title: 'UEFA winner',
            key: 'winner',
            extra_classes: ['text-align-center', 'flex-1-1']
          }
        ];
        let data = [];
        const my_username = UserService.getUsername();
        for (const k in res) {
          let obj = {
            username: res[k].username,
            finalist_1: res[k].finalist_1,
            finalist_2: res[k].finalist_2,
            winner: res[k].winner
          };
          if (my_username === obj.username) {
            obj.highlight = true;
          }
          if (UserService.isInSameGroup(res[k])) {
            obj.in_same_group = true;
          }
          data.push(obj);

        }
        this.boarddata = {data, headers};
      })
      .catch(() => {
        // NotificationService.add('danger', err);
      })
    }
  }
}

</script>