<template>
  <div class="navmenu" :class="{'show':mobile_menu_opened, 'hide':!mobile_menu_opened}">
    <router-link :to="{path: 'home'}" @click="closeMenu">Home</router-link>
    <router-link :to="{path: 'matches'}" @click="closeMenu">Matches</router-link>
    <router-link :to="{path: 'teams'}" @click="closeMenu">Teams</router-link>
    <router-link :to="{path: 'scoreboard'}" @click="closeMenu">Scoreboard</router-link>
    <router-link :to="{path: 'notifications'}" @click="closeMenu">Notifications</router-link>
    <router-link v-if="isAdmin()" :to="{path: 'admin'}" @click="closeMenu">Admin</router-link>
    <button class="logout-btn" @click="logOut">Log out</button>
  </div>
</template>

<script>

import Dataservice from '@root/services/Dataservice';
import UserService from '@root/services/UserService';
import NotificationService from '@root/generic/NotificationService';

export default {
  name: 'Menu',
  props: {
    mobile_menu_opened: Boolean
  },
  mounted () {
    // console.log(this);
  },
  methods: {
    logOut () {
      Dataservice.post("auth/logout").then(()=>{
        // Notifications.add("success", "Logged out");
        window.location.reload();
      }).catch(()=>{
        NotificationService.add("error", "Log out failed")
      })
    },
    route (page) {
      this.$emit("showPage", page);
    },
    isAdmin() {
      return UserService.isAdmin();
    },
    closeMenu () {
      this.$emit('toggleMenu');
    }
  }
}
</script>