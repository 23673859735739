'use strict';

import { clone } from '@root/helpers/Global';
import Dataservice from '@root/services/Dataservice'
import Cookies from "@root/services/Cookieservice";

const $user = {
  user: null,
  _resolvers: [],
  wasAuthed () {
    return this.user != null;
  },
  isAuthorized () {
    return new Promise((resolve) => {
      if (this.user != null) {
        resolve(true);
      } else {
        Dataservice.get("auth/me").then((user) => {
          this.user = user;
          resolve(true);
          this.pushToSubscribers();
        })
        .catch(() => {
          this.user = null;
          resolve(false);
          this.pushToSubscribers();
        })
      }
    });
  },
  refreshUser () {
    return new Promise((resolve) => {
      Dataservice.get("auth/me").then((user) => {
        this.user = user;
        resolve(true);
        this.pushToSubscribers();
      })
      .catch(() => {
        this.user = null;
        resolve(true);
        this.pushToSubscribers();
      })
    });
  },
  getUsername () {
    // console.log(this.user);
    return this.user.username || 'null';
  },
  getNotificationId() {
    // console.log(this.user);
    return this.user.notification_id || null;
  },
  getUserGroupIds () {
    if (this.user?.groups == null) {
      return [];
    }
    return Object.keys(this.user.groups);
  },
  isInSameGroup (another_user) {
    // Nb My groups is Array, and user_groups is String w commas
    if (this.user.groups == null || another_user.user_group_ids == null) {
      return false;
    }
    let another_user_groups = another_user.user_group_ids.split(',');
    let my_groups = this.user.groups;
    for (let group_id in my_groups) {
      if (another_user_groups.includes(group_id)) {
        return true;
      }
    }
    return false;
  },
  isAdmin () {
    return this.user.admin === true || false;
  },
  get () {
    return clone(this.user);
  },
  subscribe(cb, vnode = null) {
    if (vnode != null && typeof vnode.$once === 'function') {
      vnode.$once('hook:beforeDestroy', () => {
        let index = this._resolvers.indexOf(cb);
        this._resolvers.splice(index, 1);
      });
    }
    this._resolvers.push(cb);
    this.send(cb);
  },
  send(fn) {
    fn(clone(this.user));
  },
  pushToSubscribers () {
    for (const cb of this._resolvers) {
      this.send(cb);
    }
  },
  hasSeenNotification (id) {
    let cookie = Cookies.get(id);
    if (cookie == "true" || cookie == true) {
      return true;
    }
    return false;
  },
  markNotificationAsSeen(id) {
    Cookies.set(id, true);
  }
}

export default $user;