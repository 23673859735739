<template>
  <div class="users-bets">
    <div class="users-bets-togglebtn">
      <button @click="toggleUsersBetsView">{{ opened === true ? 'Close' : 'Show other\'s bets' }}</button>
    </div>
    <div class="users-bets-container" v-if="opened">
      <ScoreBoard :filterable="true" :items="bets" @refresh="fetchUsersBets" class="text-align-left"/>
    </div>
  </div>
</template>

<script>

import ScoreBoard from '@root/components/ScoreBoard';
import DataService from '@root/services/Dataservice';
import UserService from '@root/services/UserService';

export default {
  name: 'UsersBets',
  components: {
    ScoreBoard
  },
  props: {
    match: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      bets: {},
      opened: false
    }
  },
  methods: {
    hasMatchStarted () {
      return this.match.has_started;
    },
    fetchUsersBets () {
      if (this.hasMatchStarted() && this.opened) {
        DataService.get(`matches/${this.match.match_id}/users/bets`)
        .then((result) => {
          if (result.bets != null) {
            // console.log(this.match);
            // console.log(result);
            this.normalizeBetsData(result.bets);
          } else {
            // Setting data to empty;
            this.bets = {};
          }
        })
        .catch(() => {
          this.opened = false;
        });
      }
    },
    toggleUsersBetsView () {
      this.opened = !this.opened;
      if (this.opened === true) {
        this.fetchUsersBets();
      }
    },
    normalizeBetsData (result) {
      
      let headers = [
        {
          title: '#',
          key: 'rank',
          extra_classes: ['text-align-center', 'flex-grow-0']
        },
        {
          title: 'Username',
          key: 'username'
        },
        {
          title: 'Bet',
          key: 'user_bet',
          extra_classes: ['text-align-center']
        },
        {
          title: 'Penalties',
          key: 'pen_win_bet',
          extra_classes: ['text-align-center']
        },
        {
          title: 'Points',
          key: 'received_points',
          extra_classes: ['text-align-center']
        }
      ];
      let data = [];
      const my_username = UserService.getUsername();
      for (const k in result) {
        let obj = {
          rank: parseInt(k, 10) + 1,
          username: result[k].user.username,
          user_bet: `${result[k].op_1_score} - ${result[k].op_2_score}`,
          pen_win_bet: '-',
          received_points: result[k].user.points
        };
        if (result[k].pen_win != null) {
          if (result[k].pen_win === 1) {
            obj.pen_win_bet = this.match.op_1.name;
          } else if (result[k].pen_win === 2) {
            obj.pen_win_bet = this.match.op_2.name;
          }
        }
        if (my_username === obj.username) {
          obj.highlight = true;
        }
        if (UserService.isInSameGroup(result[k])) {
          obj.in_same_group = true;
        }
        data.push(obj);

      }
      this.bets = {data, headers};
    }
  }
}
</script>