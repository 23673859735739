<template>
  <div class="bet-modal">
    <div class="bet-container">
      <div class="match-date">
        {{ $filters.matchDayFilter(match.match_start)}}
        {{ $filters.matchTimeFilter(match.match_start)}}
      </div>
      <div class="match-teams">
        <div class="team-element left">
          <div class="team-flag-name">
            <div class="team-flag">
              <img :src="match.op_1.img">
            </div>
            <div class="team-name">
              <span>{{match.op_1.name}}</span>
            </div>
          </div>
          <div class="team-score">
            {{getScoreNumber(match.op_1.score)}}
          </div>
        </div>
        <span class="match-vs">
          <div class="divider"></div>
        </span>
        <div class="team-element right">
          <div class="team-flag-name">
            <div class="team-flag">
              <img :src="match.op_2.img">
            </div>
            <div class="team-name">
              <span>{{match.op_2.name}}</span>
            </div>
          </div>
          <div class="team-score">
            {{getScoreNumber(match.op_2.score)}}
          </div>
        </div>
      </div>
      <div class="match-betting match-user-betting">
        <div class="betting">
          <div class="your-bet-header">
            Make Your bet
          </div>
          <div class="match-teams">
            <div class="team-element left">
              <div class="team-score form-field">
                <input ref="autofocus_input" id="team-score-op-1" :placeholder="(op_1_input_valid === true ? '': '0')" type="number" pattern="[0-9]*" v-model="op_1_score" @change="op1_ScoreChanged" @input="op1_ScoreChanged"/>
                <!-- <label for="team-score-op-1">
                  {{match.op_1.name}}'s score {{op_1_input_valid}}
                </label> -->
              </div>
            </div>
            <span class="match-vs">
              <div class="divider"></div>
            </span>
            <div class="team-element right">
              <div class="team-score form-field">
                <input id="team-score-op-2" :placeholder="(op_2_input_valid === true ? '': '0')" type="number" pattern="[0-9]*" v-model="op_2_score" @change="op2_ScoreChanged" @input="op2_ScoreChanged"/>
                <!-- <label for="team-score-op-2">
                  {{match.op_2.name}}'s score {{op_2_input_valid}}
                </label> -->
              </div>
            </div>
          </div>
          <div class="penalties-bet" v-if="match.has_penalties === true && show_penalty_card === true">
            <div class="your-bet-header penalties">
              Select penalty shoot-out winner
            </div>
            <div class="penalty-win-selection">
              <input id="pen-win-selection-1" name="pen_win" v-model="pen_win" type="radio" value="1" />
              <label for="pen-win-selection-1">{{match.op_1.name}}</label>
              <input id="pen-win-selection-2" name="pen_win" v-model="pen_win" type="radio" value="2" />
              <label for="pen-win-selection-2">{{match.op_2.name}}</label>
              <!-- <input id="pen-win-selection-no-pen" name="pen_win" v-model="pen_win" checked type="radio" value="null" />
              <label for="pen-win-selection-no-pen">No penalties</label> -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="cancel" @click="closeModal"> Cancel </button>
        <button @click="submitBet"> Submit </button>
      </div>
    </div>
  </div>
</template>

<script>


import DataService from '@root/services/Dataservice';

import NotificationService from '@root/generic/NotificationService';

export default {
  name: 'BetModal',
  props: {
    match: Object
  },
  data () {
    return {
      pen_win: null,
      op_1_score: null,
      op_2_score: null,
      op_1_input_valid: false,
      op_2_input_valid: false,
      show_penalty_card: false
    }
  },
  mounted() {
    this.op_1_score = this.match.bet.op_1_score;
    this.op_2_score = this.match.bet.op_2_score;
    this.pen_win = this.match.bet.pen_win;
    this.op1_ScoreChanged();
    this.op2_ScoreChanged();
    this.$nextTick(() => {
      this.$refs.autofocus_input.focus();
    })
  },
  methods: {
    closeModal () {
      this.$emit('closemodal', false)
    },
    submitBet () {
      if (this.match.has_penalties === true && Number(this.op_1_score) == Number(this.op_2_score) && this.pen_win == null) {
        NotificationService.add('warning', 'Please select penalty shootout winner');
        return;
      }
      let bet = {
        match_id: this.match.match_id,
        pen_win: this.pen_win,
        op_1_score: Number(this.op_1_score),
        op_2_score: Number(this.op_2_score)
      }
      DataService.post(`matches/${bet.match_id}/bet`, bet)
      .then(() => {
        // console.log('result', result);
        this.$emit('closemodal', true);
      })
      .catch(() => {
        this.$emit('closemodal', false);
      })
    },
    getScoreNumber () {
      return '•';
    },
    op1_ScoreChanged () {
      if (this.op_1_score == null || String(this.op_1_score).length < 1 || isNaN(Number(this.op_1_score))) {
        this.op_1_input_valid = false;
      } else {
        this.op_1_input_valid = true;
      }
      this.togglePenaltyInput();
    },
    op2_ScoreChanged () {
      if (this.op_2_score == null || String(this.op_2_score).length < 1 || isNaN(Number(this.op_2_score))) {
        this.op_2_input_valid = false;
      } else {
        this.op_2_input_valid = true;
      }
      this.togglePenaltyInput();
    },
    togglePenaltyInput () {
      if (Number(this.op_1_score) == Number(this.op_2_score)) {
        this.show_penalty_card = true;
      } else {
        this.show_penalty_card = false;
      }
    }
  }
}
</script>