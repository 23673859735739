<template>
  <div class="match-component" :class="{'inactive': !can_bet_on_match ,'last': last_in_list}">
    <BetModal v-if="selected_for_betting" :match="match" @closemodal="closeBetModal"/>
    <div class="match-date">
      {{ $filters.matchTimeFilter(match.match_start)}}
    </div>
    <div class="match-teams">
      <div class="team-element left">
        <div class="team-flag-name">
          <div class="winner-trophy" v-if="isWinner(1)">
            <div class="trophy">
              <img src="../assets/images/trophy.svg">
            </div>
          </div>
          <div class="team-flag">
            <img :src="match.op_1.img">
          </div>
          <div class="team-name">
            <span>{{match.op_1.name}}</span>
          </div>
          <div class="penalty-win" v-if="isPenaltyWinner(1)">
            (Penalty shoot-out win)
          </div>
        </div>
        <div class="team-score">
          {{getScoreNumber(match.op_1.score)}}
        </div>
      </div>
      <span class="match-vs">
        <div class="divider"></div>
      </span>
      <div class="team-element right">
        <div class="team-flag-name">
          <div class="winner-trophy" v-if="isWinner(2)">
            <div class="trophy">
              <img src="../assets/images/trophy.svg">
            </div>
          </div>
          <div class="team-flag">
            <img :src="match.op_2.img">
          </div>
          <div class="team-name">
            <span>{{match.op_2.name}}</span>
          </div>
          <div class="penalty-win" v-if="isPenaltyWinner(2)">
            (Penalty shoot-out win)
          </div>
        </div>
        <div class="team-score">
          {{getScoreNumber(match.op_2.score)}}
        </div>
      </div>
    </div>
    <div class="match-betting match-user-betting">
      <template v-if="match.bet.bet_id != null">
        <div class="betting">
          <div class="your-bet-header">
            Your bet
          </div>
          <div class="match-teams">
            <div class="team-element left">
              <div class="team-flag-name"></div>
              <div class="team-score">
                {{match.bet.op_1_score}}
              </div>
            </div>
            <span class="match-vs">
              <div class="divider"></div>
            </span>
            <div class="team-element right">
              <div class="team-flag-name"></div>
              <div class="team-score">
                {{match.bet.op_2_score}}
              </div>
            </div>
          </div>
          <div class="penalties-bet" v-if="match.bet.pen_win != null">
            <div class="your-bet-header penalties">
              Your bet for penalty winner
            </div>
            <span>{{sanitizePenWin(match.bet)}}</span>
          </div>
          <div class="match-betting betting-ended" v-if="!can_bet_on_match">
            Betting ended for this match
          </div>
          <button @click="openBetModal" v-else>Change bet</button>
        </div>
      </template>
      <template v-else>
        <div>
          <div class="match-betting betting-ended" v-if="!can_bet_on_match">
            Betting ended for this match
          </div>
          <button @click="openBetModal(match)" v-else>Bet</button>
        </div>
      </template>
      <UsersBets v-if="!can_bet_on_match" :match="match" />
    </div>
    <div class="match-divider" v-if="!last_in_list"></div>
  </div>
</template>

<script>

import DataService from '@root/services/Dataservice';
import BetModal from '@root/components/BetModal';
import UsersBets from '@root/components/UsersBets';

export default {
  name: 'Match',
  components: {
    BetModal,
    UsersBets
  },
  props: {
    match: Object,
    index: Number,
    date_group_key: String,
    last_in_list: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected_for_betting: false,
      can_bet_on_match: false
    }
  },
  mounted() {
    this.can_bet_on_match = !this.hasMatchStarted();
  },
  created() {
  //  console.log('MATCH CREATED');
  },
  methods: {
    hasMatchStarted () {
      return this.match.has_started;
    },
    openBetModal () {
      this.selected_for_betting = true;
    },
    closeBetModal (refresh) {
      this.selected_for_betting = false;
      if (refresh === true) {
        this.refresh();
      }
    },
    sanitizePenWin (bet) {
      // console.log('User bet', bet.bet_id);
      const pen_win = parseInt(bet.pen_win, 10);
      if (pen_win == null || isNaN(pen_win)) {
        return 'No penalties';
      }
      if (pen_win === 1) {
        return this.match.op_1.name ;
      } else {
        return this.match.op_2.name;
      }
    },
    refresh () {
      DataService.get(`matches/${this.match.match_id}`)
      .then((result) => {
        if (result != null) {
          // console.log('MATCH CALLING REFRESH', this.date_group_key, this.index)
          this.$emit('refresh', this.date_group_key, this.index, result);
        }
      })
      .catch(() => {
        // Dataservice probably handles error already
      })
    },
    getScoreNumber (score) {
      if (this.can_bet_on_match) {
        return '•';
      }
      return score;
    },
    isWinner(id) {
      // Add three hours -- maybe in future can add match started and match ended statuses
      if (this.match.uefa_status != 'FINISHED') {
        return false;
      }

      if (this.match.pen_win == id) {
        return true;
      }
      if (id == 1 && this.match.op_1.score > this.match.op_2.score) {
        return true;
      } else if (id == 2 && this.match.op_2.score > this.match.op_1.score) {
        return true;
      } else if (this.match.op_1.score == this.match.op_2.score && this.match.pen_win == null) {
        return true;
      }

      return false;
    },
    isPenaltyWinner (id) {
      if (this.match.pen_win == id) {
        return true;
      }
      return false;
    }
  }
}
</script>