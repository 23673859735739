/*
 * Notification service for VUE
 * Used to share notification information across components
 *
 * usage:
 * import NotificationService from '$cdn/Services/NotificationService';
 * NotificationService.add(type, msg);
 *
 * Find other methods inside the file
 */

'use strict';

export default {
    _notifications_allowed: true,
    _notifications: {
        // having this null lets navbar know that to hide the content before it loads
        list: [],
        display_type: null,
        active_notification: null,
    },
    add(type, msg, close = 5000) {
      if (this._notifications_allowed === false) {
        return false;
      }
      if (type === 'danger' || type === 'error') {
        type = 'error';
      }
      window.$notification[type]({
        content: msg,
        duration: close,
        keepAliveOnHover: true
      });
    },
    setDisplayTypeOnly(name) {
      this._notifications.display_type = name;
    },
    disable () {
      this._notifications_allowed = false;
    },
    enable () {
      this._notifications_allowed = true;
    }
};
