<template>
    <div class="modal-loading">
      <div></div>
    </div>
</template>

<script>


export default {
  name: "ModalLoading"
}
</script>

<style scoped>

.modal-loading {
    position: relative;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
    text-align: center;
    background-color: transparent;
    z-index: 200;
    opacity: 0;
}

.modal-loading.fade-in {
  -webkit-animation: fadein 0.2s ease-in-out forwards; /* Safari 4+ */
  -moz-animation:    fadein 0.2s ease-in-out forwards; /* Fx 5+ */
  -o-animation:      fadein 0.2s ease-in-out forwards; /* Opera 12+ */
  animation:         fadein 0.2s ease-in-out forwards; /* IE 10+, Fx 29+ */
}

.modal-loading.fade-out {
  -webkit-animation: fadeout 0.2s ease-in-out forwards; /* Safari 4+ */
  -moz-animation:    fadeout 0.2s ease-in-out forwards; /* Fx 5+ */
  -o-animation:      fadeout 0.2s ease-in-out forwards; /* Opera 12+ */
  animation:         fadeout 0.2s ease-in-out forwards; /* IE 10+, Fx 29+ */
}


.modal-loading > div {
    -webkit-animation: loading-bar-animation 1s ease-in-out infinite; /* Safari 4+ */
    -moz-animation:    loading-bar-animation 1s ease-in-out infinite; /* Fx 5+ */
    -o-animation:      loading-bar-animation 1s ease-in-out infinite; /* Opera 12+ */
    animation:         loading-bar-animation 1s ease-in-out infinite; /* IE 10+, Fx 29+ */
    background: #f7a600;
    width: 4%;
    height: 100%;
    top: 0;
    transform: translateX(0);
    margin-top: 12px;
    
}

@-webkit-keyframes loading-bar-animation {
  0%{visibility: hidden; width: 10%;margin-left: -10%;}
  1%{visibility: visible; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {visibility: hidden; margin-left:110%;width:10%;}
}
@-moz-keyframes loading-bar-animation {
  0%{visibility: hidden; width: 10%; margin-left: -10%;}
  1%{visibility: visible; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {visibility: hidden; margin-left:110%;width:10%;}
}
@-o-keyframes loading-bar-animation {
  0%{visibility: hidden; width: 10%; margin-left: -10%;}
  1%{visibility: visible; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {visibility: hidden; margin-left:110%;width:10%;}
}
@keyframes loading-bar-animation {
  0%{visibility: hidden; width: 10%; margin-left: -10%;}
  1%{visibility: visible; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {visibility: hidden; margin-left:110%;width:10%;}
}

@-webkit-keyframes fadein {
  from {opacity:0; width: 0%;}
  to {opacity:1; width: 100%;}
}
@-moz-keyframes fadein {
  from {opacity:0; width: 0%;}
  to {opacity:1; width: 100%;}
}
@-o-keyframes fadein {
  from {opacity:0; width: 0%;}
  to {opacity:1; width: 100%;}
}
@keyframes fadein {
  from {opacity:0; width: 0%;}
  to {opacity:1; width: 100%;}
}

@-webkit-keyframes fadeout {
  from {opacity:1; width: 100%;}
  to {opacity:0; width: 0%;}
}
@-moz-keyframes fadeout {
  from {opacity:1; width: 100%;}
  to {opacity:0; width: 0%;}
}
@-o-keyframes fadeout {
  from {opacity:1; width: 100%;}
  to {opacity:0; width: 0%;}
}
@keyframes fadeout {
  from {opacity:1; width: 100%;}
  to {opacity:0; width: 0%;}
}


.modal-loading svg {
    width: 100%;
    height: 160px;
}
</style>