
<script>
import { useNotification } from "naive-ui";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    window.$notification = useNotification();
  }
});
</script>

<style lang="scss">
.n-notification.n-notification--closable.n-notification--show-avatar {
  border: 2px solid #f7a600;
}
</style>