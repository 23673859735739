import '@root/assets/css/main.scss';

import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import { globalCookiesConfig } from "vue3-cookies";

globalCookiesConfig({
  expireTimes: "90d",
  secure: true
});

const store = createStore({
  state() {
    return {
      bonusbets_deadline: 1719662400000,
      bonuscards_notif_cookie_id: 'notif_bonuscards_2024',
      payment_reminder_cookie_id: 'payment_reminder_2024',
      can_show_payment_reminder: false,
      can_show_upsell: false
    }
  }
})

const app = createApp(App)


// app.component('edit-icon', EditIcon)
//   .component('check-circle', CheckCircle)
//   .component('delete-icon', DeleteIcon)

app.use(store)
app.use(globalCookiesConfig).use(router).mount('#app')
app.config.globalProperties.$apibaseurl = process.env.NODE_ENV !== 'production' ? 'http://localhost:3338/' : 'https://services-api.clickndrive.ee/'
app.config.globalProperties.$filters = {
  matchDateFilter(value) {
    return new Date(value).toLocaleString();
  },
  matchDayFilter(value) {
    return new Date(value).toDateString();
  },
  matchTimeFilter(value) {
    const date = new Date(value).toLocaleTimeString();
    return date.substring(0, 5);
  },
  longDateFilter(value) {
    return new Date(value).toString();
  }
}




export {
  app,
  router
}

// Vue.$cookies.config('90d', '/', process.env.NODE_ENV == 'production' ? '.clickndrive.ee' : 'localhost');
