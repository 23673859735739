<template>
 <div class="register-page">
   <h2>Create your user</h2>
    <form @submit="register">
      <div class="form-field">
        <input ref="username" id="username" type="text" autocorrect="off" autocapitalize="none"  autocomplete="username" required/>
        <label for="username">
            Username
        </label>
      </div>
      <div class="form-field">
        <input ref="password" id="password" type="password" autocomplete="new-password" required/>
        <label for="password">
            Password
        </label>
      </div>
      <div class="form-field">
        <input ref="confirm_password" id="confirm-password" type="password" autocomplete="new-password" required/>
        <label for="confirm-password">
            Confirm Password
        </label>
      </div>
      <button type="submit">
          Register
      </button>
    </form>
 </div>
</template>

<script>

import Dataservice from '@root/services/Dataservice';
import LoadingService from '@root/generic/LoadingService';
import NotificationService from '../generic/NotificationService';

export default {
  name: 'Register',
  components: {

  },
  created() {
    if (this.$route.query == null || this.$route.query.g == null) {
      NotificationService.add("warning", "Registering is invite only");
    }
  },
  methods: {
    register() {
      event.preventDefault();
      if (LoadingService.isLoading()) {
        return;
      }
      console.log('ROUTE', this.$route);
      LoadingService.showLoading();
      Dataservice.post('register', {
          "username": this.$refs.username.value,
          "password": this.$refs.password.value,
          "confirmation_password": this.$refs.confirm_password.value,
          "group_id": this.$route.query.g
      }).then(() => {
          this.$router.push('login');
          // window.location.reload();
      }).catch((err) => {
        console.log('Error at register', err);
        NotificationService.add('danger', err.message.msg || 'Registration failed', 6000);
      }).finally(() => {
        LoadingService.hideLoading();
      })
    }
  }
}
</script>

