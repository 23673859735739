<template>
  <div class="main">
    <BonusBetsResults v-if="is_time_for_bet_results"/>
    <BonusBets v-else />
    <UpcomingMatches />
    <TopTenBoard />
    <UserGroupsStandings />
    <Popup
      v-if="show_popup"
      :cookie_id="$store.state.bonuscards_notif_cookie_id"
      message="Place your bet for UEFA 2024 1st and 2nd finalists and UEFA 2024 winner before 29th of June 15:00 from HOME page"
      @close="show_popup = false"/>
    <Popup
      v-if="show_payment_reminder"
      :cookie_id="$store.state.payment_reminder_cookie_id"
      @close="show_payment_reminder = false">
      <PaymentReminder />
    </Popup>
  </div>
</template>

<script>

import Popup from '@root/components/Popup';
import PaymentReminder from '@root/components/PaymentReminder';
import BonusBets from '@root/pages/home/BonusBets';
import BonusBetsResults from '@root/pages/home/BonusBetsResults';
import TopTenBoard from '@root/pages/home/TopTenBoard';
import UpcomingMatches from '@root/pages/home/UpcomingMatches';
import UserGroupsStandings from '@root/pages/home/UserGroupsStandings';

import UserService from '@root/services/UserService';
import NotificationService from '@root/generic/NotificationService';

export default {
  name: 'Home',
  components: {
    PaymentReminder,
    Popup,
    BonusBets,
    BonusBetsResults,
    TopTenBoard,
    UpcomingMatches,
    UserGroupsStandings
  },
  data () {
    return {
      show_popup: false,
      show_payment_reminder: false,
      is_time_for_bet_results: false
    }
  },
  mounted () {
    this.is_time_for_bet_results = new Date().getTime() > this.$store.state.bonusbets_deadline; // 29. juuni 2024, kell 15:00 GMT+3 - UTC 12:00
    if (this.$store.state.can_show_upsell && UserService.hasSeenNotification(this.$store.state.bonuscards_notif_cookie_id) === false) {
      this.show_popup = true;
    }
    if (this.$store.state.can_show_payment_reminder && UserService.hasSeenNotification(this.$store.state.payment_reminder_cookie_id) === false) {
      this.show_payment_reminder = true;
    }
  },
  methods: {
    copyText(val) {
      navigator.clipboard.writeText(val).then(() => {
        NotificationService.add('success', `${val} copied to clipboard!`);
      }).catch(() => {
        // NotificationService.add('warning', `couldn't copy ${val}`);
      })
    }
  }
}
</script>