<template>
    <header :class="{'mobile-menu-opened': mobile_menu_opened}">
      <div class="upper-part">
        <div class="logo" @click="goHome">
            OKSAKOHT
        </div>
        <Menu v-if="user != null" @toggleMenu="toggleMenu" :mobile_menu_opened="mobile_menu_opened"/>
        <div v-if="user != null" class="mobile-burger" @click="toggleMenu">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <UserStats v-if="user != null" />
    </header>
</template>

<script>

import Menu from '@root/components/Menu';
import UserStats from '@root/components/UserStats';
import UserService from '@root/services/UserService';

export default {
    name: "Header",
    components: {
      Menu,
      UserStats
    },
    data () {
        return {
            user: null,
            mobile_menu_opened: false
        }
    },
    mounted() {
      // Listen for user changes
      UserService.subscribe((user) => {
        this.user = user;
        // console.log('User ', this.user);
      }, this);
    },
    methods: {
        toggleMenu () {
          // console.log('OPENING MENU');
          this.mobile_menu_opened = !this.mobile_menu_opened;
        },
        goHome() {
          this.$router.push('home').catch(() => {

          });
        }
    },
}
</script>

