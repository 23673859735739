<template>
    <div class="login-page">
        <h1>Log in</h1>
        <form @submit="signIn">
          <div class="form-field">
            <input ref="username" id="username" type="text" autocorrect="off" autocapitalize="none" autocomplete="username" required/>
            <label for="username">
                Username
            </label>
          </div>
          <div class="form-field">
            <input ref="password" id="password" type="password" autocomplete="password" required/>
            <label for="password">
                Password
            </label>
          </div>
          <button type="submit">
              Log in
          </button>
        </form>
    </div>
</template>

<script>

import Dataservice from '@root/services/Dataservice';
import LoadingService from '@root/generic/LoadingService';

export default {
  name: 'Login',
  components: {

  },
  methods: {
    signIn() {
      event.preventDefault();
      if (LoadingService.isLoading()) {
        return;
      }
      LoadingService.showLoading();
      Dataservice.post('auth/login', {
          "username": this.$refs.username.value,
          "password": this.$refs.password.value
      }).then(() => {
          window.location.reload();
      }).catch(() => {

      }).finally(() => {
          LoadingService.hideLoading();
      })
    }
  }
}
</script>

<style scoped>
.login-page {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
}

.login-page form button {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
}

form label, form input {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
}


</style>