<template>
  <div class="copylink">
    <button class="copylink-btn" @click="copyGroupInviteURL">Get invite url</button>
  </div>
</template>

<script>

import NotificationService from '@root/generic/NotificationService';

export default {
  name: 'CopyLink',
  props: {
    path: String
  },
  methods: {
    copyGroupInviteURL() {
      if (navigator.clipboard.writeText(`${window.location.origin}/${this.path}`)) {
        NotificationService.add('success', 'URL copied to clipboard');
      } else {
        NotificationService.add('danger', 'Could not copy link');
      }
    }
  }  
}
</script>