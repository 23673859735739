<template>
  <div class="modal">
    <div class="modal-header">
     My standings in groups
    </div>
    <div class="modal-content">
      <button class="scoreboard-refresh" @click="fetchUserStandings">Refresh table</button>
      <ModalLoading :class="{'fade-in': is_loading === true, 'fade-out': is_loading !== true}"/>
      <div class="group-standing-container" v-for="(group, group_id) in groups" :key="group_id">
        <h3>{{group.name}}</h3>
        <ScoreBoard :items="normalizeGroupDataForScoreboard(group.users)" :has_loading="false" :refreshable="false"/>
      </div>
    </div>
  </div>
</template>


<script>

import ScoreBoard from '@root/components/ScoreBoard';
import DataService from '@root/services/Dataservice';
import UserService from '@root/services/UserService';
import NotificationService from '@root/generic/NotificationService';
import ModalLoading from '@root/components/ModalLoading';

export default {
  name: 'TopTenBoard',
  components: {
    ScoreBoard,
    ModalLoading
  },
  data () {
    return {
      groups: {},
      is_loading: false
    }
  },
  mounted () {
    this.fetchUserStandings();
  },
  methods : {
    fetchUserStandings () {
      this.is_loading = true
      DataService.get('stats/my/groups')
      .then((result) => {
        this.groups = result;
        // setTimeout(() => {
          this.is_loading = false;
        // }, 1000);
      })
      .catch(() => {
        NotificationService.add('danger', 'Could not show group standings');
        this.groups = {};
        this.is_loading = false;
      })
    },
    normalizeGroupDataForScoreboard (users) {
      let headers = [{
          title: '#',
          key: 'rank'
        },
        {
          title: 'Username',
          key: 'username'
        },
        {
          title: 'Points',
          key: 'points'
        },
        {
          title: '13\'s',
          key: 'thirteen'
        },
        {
          title: '10\'s',
          key: 'ten'
        },
        {
          title: '7\'s',
          key: 'seven'
        },
        {
          title: '4\'s',
          key: 'four'
        },
        {
          title: '3\'s',
          key: 'three'
        },
        {
          title: '0\'s',
          key: 'zero'
        }
      ];
      let data = [];
      const my_username = UserService.getUsername();
      const my_groups = UserService.getUserGroupIds();
      for(const k in users) {
        let obj = {
          rank: parseInt(k, 10) + 1,
          username: users[k].username,
          points: users[k].user_score,
          thirteen: users[k].user_thirteen,
          ten: users[k].user_tens,
          seven: users[k].user_sev,
          four: users[k].user_four,
          three: users[k].user_three,
          zero: users[k].user_zero
        };
        if (my_username === obj.username) {
          obj.highlight = true;
        }
        if (my_groups.indexOf(users[k].user_group_id) >= 0) {
          obj.in_same_group = true;
        }
        data.push(obj);
      }
      return {data, headers};
    }
  }
}

</script>