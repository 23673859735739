<template>
 <div class="register-page">
   <h2>Resetting your password</h2>
    <form @submit="resetPassword">
      <label for="password">
          Password:
      </label>
      <input ref="password" id="password" type="password" required/>
      <label for="confirm-password">
          Confirm password:
      </label>
      <input ref="confirm_password" id="confirm-password" type="password" required/>
      <button type="submit">
          Reset
      </button>
    </form>
 </div>
</template>

<script>

import Dataservice from '@root/services/Dataservice';
import LoadingService from '@root/generic/LoadingService';
import NotificationService from '../generic/NotificationService';

export default {
  name: 'PasswordReset',
  components: {

  },
  created() {
    if (this.$route.query == null || this.$route.query.uid == null) {
      NotificationService.add("warning", "This page cannot reset password (err:1)");
    }
    if (this.$route.query == null || this.$route.query.tkn == null) {
      NotificationService.add("warning", "This page cannot reset password (err:2)");
    }
  },
  methods: {
    resetPassword() {
      event.preventDefault();
      if (LoadingService.isLoading()) {
        return;
      }
      LoadingService.showLoading();
      Dataservice.post(`auth/users/${this.$route.query.uid}/reset/password`, {
          "password": this.$refs.password.value,
          "confirmation_password": this.$refs.confirm_password.value,
          "tkn": this.$route.query.tkn
      }).then(() => {
          this.$router.push('login');
      }).catch((err) => {
        NotificationService.add('danger', err.message.msg || 'Could not reset password', 6000);
      }).finally(() => {
        LoadingService.hideLoading();
      })
    }
  }
}
</script>

