<template>
  <div class="main">
    <div class="modal">
      <div class="modal-header">
      Scoreboard
      </div>
      <div class="modal-content">
        <ScoreBoard :filterable="true" :items="overall_rankings" @refresh="fetchOverallRankings"/>
      </div>
    </div>
  </div>
</template>

<script>

import ScoreBoard from '@root/components/ScoreBoard';
import DataService from '@root/services/Dataservice';
import UserService from '@root/services/UserService';
import NotificationService from '@root/generic/NotificationService';

export default {
  name: 'Scoreboard',
  components: {
    ScoreBoard
  },
  data () {
    return {
      overall_rankings: {}
    }
  },
  mounted () {
    this.fetchOverallRankings();
  },
  methods: {
    fetchOverallRankings () {
      DataService.get('stats/scoreboard')
      .then((result) => {
        this.normalizeFetchedData(result);
      })
      .catch(() => {
        NotificationService.add('danger', 'Could not show overall scoreboard');
      })
    },
    normalizeFetchedData(users) {
      let headers = [{
          title: '#',
          key: 'rank'
        },
        {
          title: 'Username',
          key: 'username'
        },
        {
          title: 'Points',
          key: 'points'
        },
        {
          title: '13\'s',
          key: 'thirteen'
        },
        {
          title: '10\'s',
          key: 'ten'
        },
        {
          title: '7\'s',
          key: 'seven'
        },
        {
          title: '4\'s',
          key: 'four'
        },
        {
          title: '3\'s',
          key: 'three'
        },
        {
          title: '0\'s',
          key: 'zero'
        },
        {
          title: 'Bonus',
          key: 'bonus'
        }
      ];
      let data = [];
      const my_username = UserService.getUsername();
      for(const k in users) {
        let obj = {
          rank: parseInt(k, 10) + 1,
          username: users[k].username,
          points: users[k].user_score,
          thirteen: users[k].user_thirteen,
          ten: users[k].user_tens,
          seven: users[k].user_sev,
          four: users[k].user_four,
          three: users[k].user_three,
          zero: users[k].user_zero,
          bonus: users[k].user_bonus
        };
        if (my_username === obj.username) {
          obj.highlight = true;
        }
        if (UserService.isInSameGroup(users[k])) {
          obj.in_same_group = true;
        }
        data.push(obj);
      }
      this.overall_rankings = {data, headers};
    }
  }
}
</script>