<template>
    <div class="loading" :class="{'fade-in': state.active === true, 'fade-out': state.active !== true}">
      <div></div>
    </div>
</template>

<script>

import LoadingService from '@root/generic/LoadingService';

export default {
    name: "Loading",
    data() {
      return {
        // Objects will be checked after change
        state: LoadingService.getState()
      }
    }
}
</script>

<style scoped>
.loading {
    position: fixed;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0;
    background-color: transparent;
    z-index: 200;
    overflow: hidden;
}


.loading.fade-in {
  -webkit-animation: fadein 0.2s ease-in-out forwards; /* Safari 4+ */
  -moz-animation:    fadein 0.2s ease-in-out forwards; /* Fx 5+ */
  -o-animation:      fadein 0.2s ease-in-out forwards; /* Opera 12+ */
  animation:         fadein 0.2s ease-in-out forwards; /* IE 10+, Fx 29+ */
}

.loading.fade-out {
  -webkit-animation: fadeout 0.2s ease-in-out forwards; /* Safari 4+ */
  -moz-animation:    fadeout 0.2s ease-in-out forwards; /* Fx 5+ */
  -o-animation:      fadeout 0.2s ease-in-out forwards; /* Opera 12+ */
  animation:         fadeout 0.2s ease-in-out forwards; /* IE 10+, Fx 29+ */
}

.loading > div {
    -webkit-animation: loading-bar-animation 1.5s linear infinite; /* Safari 4+ */
    -moz-animation:    loading-bar-animation 1.5s linear infinite; /* Fx 5+ */
    -o-animation:      loading-bar-animation 1.5s linear infinite; /* Opera 12+ */
    animation:         loading-bar-animation 1.5s linear infinite; /* IE 10+, Fx 29+ */
    background: #f7a600;
    width: 10%;
    height: 100%;
    top: 0;
    margin-left: -10%;
    
}

@-webkit-keyframes loading-bar-animation {
  0%{opacity: 0; width: 10%;}
  1%{opacity: 1; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {opacity: 0; margin-left:110%;width:10%;}
}
@-moz-keyframes loading-bar-animation {
  0%{opacity: 0; width: 10%;}
  1%{opacity: 1; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {opacity: 0; margin-left:110%;width:10%;}
}
@-o-keyframes loading-bar-animation {
  0%{opacity: 0; width: 10%;}
  1%{opacity: 1; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {opacity: 0; margin-left:110%;width:10%;}
}
@keyframes loading-bar-animation {
  0%{opacity: 0; width: 10%;}
  1%{opacity: 1; margin-left: -10%; width: 10%;}
  98% {width:100%;}
  99%{margin-left: 110%;opacity: 1;width:10%;}
  100% {opacity: 0; margin-left:110%;width:10%;}
}


@-webkit-keyframes fadein {
  from {opacity:0;}
  to {opacity:1;}
}
@-moz-keyframes fadein {
  from {opacity:0;}
  to {opacity:1;}
}
@-o-keyframes fadein {
  from {opacity:0;}
  to {opacity:1;}
}
@keyframes fadein {
  from {opacity:0;}
  to {opacity:1;}
}


@-webkit-keyframes fadeout {
  from {opacity:1;}
  to {opacity:0;}
}
@-moz-keyframes fadeout {
  from {opacity:1;}
  to {opacity:0;}
}
@-o-keyframes fadeout {
  from {opacity:1;}
  to {opacity:0;}
}
@keyframes fadeout {
  from {opacity:1;}
  to {opacity:0;}
}

.loading svg {
    width: 100%;
    height: 160px;
}
</style>