<template>
  <div class="main admin">
    <div>
      <button @click="updateUserScores">Update scores</button>
      <button @click="updateMatches">Update matches</button>
    </div>
    <AdminUsers />
    <AdminMatches />
  </div>
</template>

<script>

import AdminUsers from '@root/pages/admin/AdminUsers';
import AdminMatches from '@root/pages/admin/AdminMatches';

import DataService from '@root/services/Dataservice';
import NotificationService from '../generic/NotificationService';

export default {
  name: 'Admin',
  components: {
    AdminUsers,
    AdminMatches
  },
  methods: {
    updateUserScores () {
      DataService.post('admin/update/scores').then(() => {
        NotificationService.add('success', 'Updated');
      })
      .catch((err) => {
        NotificationService.add('danger', err);
      })
    },
    updateMatches() {
      DataService.post('admin/update/matches').then(() => {
        NotificationService.add('success', 'Matches Updated');
      })
        .catch((err) => {
          NotificationService.add('danger', err);
        })
    }
  }
}
</script>