<template>
  <div>
    <div class="bet-container">
      <h2>Maksa 10€ Kenni kontole, kui pole makstud</h2>
      <p>Time left: {{ left_hours }} Hours | {{ left_minutes }} Minutes | {{ left_seconds }} {{ seconds_txt }}</p>
      <p @click="copyText('EE871010011598180220')"><strong>EE871010011598180220</strong></p>
      <p @click="copyText('Kenn Rull')"><strong>Kenn Rull</strong></p>
      <p><strong>10€</strong></p>
    </div>
  </div>
</template>

<script>

import Cookies from "@root/services/Cookieservice";
import NotificationService from '@root/generic/NotificationService';

export default {
  name: 'Popup',
  props: {
    cookie_id: String,
    message: String
  },
  computed: {
    seconds_txt() {
      return this.left_seconds === 1 ? 'second' : 'seconds';
    },
  },
  data () {
    return {
      left_hours: 0,
      left_minutes: 0,
      left_seconds: 0,
      final: this.$store.state.bonusbets_deadline,
      intervalCounter: null,
    }
  },
  mounted() {
    this.startTimeCounter();
  },
  beforeUnmount() {
    if (this.intervalCounter != null) {
      clearInterval(this.intervalCounter);
      this.intervalCounter = null;
    }
  },
  methods: {
    closePopup () {
      Cookies.set(this.cookie_id, true);
      this.$emit('close');
    },
    startTimeCounter() {
      this.intervalCounter = setInterval(() => {
        let current = new Date().getTime();
        let totalseconds = (this.final - current) / 1000;
        this.left_hours = Math.floor(totalseconds / 3600);
        this.left_minutes = Math.floor((totalseconds % 3600) / 60);
        this.left_seconds = Math.floor((totalseconds % 60));

      }, 100);
    },
    copyText (val) {
      navigator.clipboard.writeText(val).then(() => {
        NotificationService.add('success', `${val} copied to clipboard!`);
      }).catch(() => {
        // NotificationService.add('warning', `couldn't copy ${val}`);
      })
    }
  }
}
</script>