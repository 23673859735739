<template>
  <div class="modal">
    <div class="modal-header">
     Next matches
    </div>
    <div class="modal-content">
      <ModalLoading :class="{'fade-in': is_loading === true, 'fade-out': is_loading !== true}"/>
      <div class="match-day" v-for="(date_group, date_group_key) in grouped_matches" :key="date_group_key">
        <div class="match-day-date">
          {{ $filters.matchDayFilter(date_group.date) }}
        </div>
        <Match v-for="(match, index) in date_group.matches" :match="match" :key="match.match_id" :index="index" :date_group_key="date_group_key" @refresh="refresh" :last_in_list="index+1 === date_group.matches.length"/>
      </div>
    </div>
  </div>
</template>


<script>

import DataService from '@root/services/Dataservice';
import ModalLoading from '@root/components/ModalLoading';
import Match from '@root/components/Match';

export default {
  name: 'UpcomingMatches',
  components: {
    ModalLoading,
    Match
  },
  data () {
    return {
      grouped_matches: {},
      is_loading: false
    }
  },
  mounted () {
    this.init();
  },
  methods : {
    init () {
      this.is_loading = true;
      this.fetchUpcomings();
    },
    fetchUpcomings () {
      DataService.get('dashboard/upcoming').then((result) => {
        for (const k in result) {
          const date_key = this.getDateKey(result[k].match_start);
          if (this.grouped_matches[date_key] == null) {
            this.grouped_matches[date_key] = {
              date: result[k].match_start,
              matches: []
            };
          }
          this.grouped_matches[date_key].matches.push(result[k]);
        }

        this.is_loading = false;
      }).catch(() => {
        this.grouped_matches = {};
        this.is_loading = false;
      })
    },
    refresh (grouping_index, at_index, new_data) {

      // This one seems to refresh only one element as well - idk
      let groups = this.grouped_matches
      this.grouped_matches = {};
      groups[grouping_index].matches.splice(at_index, 1, new_data);
      this.grouped_matches = groups;

      // This one doesn't work
      // this.grouped_matches[grouping_index].matches.splice(at_index, 1, new_data);
    },
    getDateKey (ts) {
      const date = new Date(ts);
      return `_${date.getDate()}_${date.getMonth()}_${date.getFullYear()}`;
    }
  }
}

</script>