
'use strict';


export default {
    state: {
      active: false
    },
    showLoading () {
      this.state.active = true;
    },
    hideLoading () {
      this.state.active = false;
    },
    isLoading() {
      return this.state.active;
    },
    getState () {
      return this.state;
    }
};
