<template>
  <div class="main">
    <div class="modal">
      <div class="modal-header">
        Notifications
      </div>
      <div class="modal-content">
        <h1 class="green" v-if="notification_id != null">Notifications Enabled</h1>
        <button v-if="notification_id != null" @click="sendTestNotification">
          Send test notification
        </button>
        <p>Notifications to let you know whenever match is starting soon (in 30min) and you haven't placed your bets
          yet.</p>
        <h1>How to</h1>
        <p>Click on "Generate QR Code" button.</p>
        <p>QR code will be generated. And this redirects you to download a simple notification app</p>
        <p>This is just an simple app which will be able to send you a notifications.</p>
        <p>QR code can be read with mobile phone OR if you're on your phone, use the provided link instead.</p>
        <button @click="generateQRCode">
          Generate QR Code
        </button>
        <img v-if="qr_img != null" :src="qr_img" />
        <a v-if="qr_link != null" :href="qr_link">Enable via link</a>
      </div>
    </div>
  </div>
</template>

<script>

import DataService from '@root/services/Dataservice';
import UserService from '@root/services/UserService';
import LoadingService from '@root/generic/LoadingService';
import NotificationService from '@root/generic/NotificationService';

export default {
  name: 'Settings',
  components: {
  },
  data () {
    return {
      notification_id: null,
      qr_img: null,
      qr_link: null
    }
  },
  mounted () {
    this.fetchUserAPIKey();
  },
  methods: {
    generateQRCode () {
      LoadingService.showLoading();
      DataService.get('auth/me/notifications/qrcode', {
      }).then((result) => {
        this.qr_img = result.image_url;
        this.qr_link = result.direct_link;
        NotificationService.add('success', 'QR code generated');
      }).catch((err) => {
        console.log(err)
        NotificationService.add('danger', 'Failed to create QR code');
      }).finally(() => {
        LoadingService.hideLoading();
      })
    },
    sendTestNotification() {
      DataService.post('auth/me/notifications/test', {
      }).then(() => {
        NotificationService.add('success', 'If notifications were set up - you should receive one shortly.');
      }).catch((err) => {
        console.log(err)
        NotificationService.add('danger', 'Failed to send notification');
      })
    },
    fetchUserAPIKey () {
      this.notification_id = UserService.getNotificationId();
    },
    saveKey () {
      event.preventDefault();
      if (LoadingService.isLoading()) {
        return;
      }
      LoadingService.showLoading();
      DataService.put('auth/me/notifications', {
        "notification_id": this.notification_id,
      }).then(() => {
        NotificationService.add('success', 'You will receive sample notification in any second');
        UserService.refreshUser();
      }).catch(() => {
        NotificationService.add('danger', 'Could not enable notifications');
      }).finally(() => {
        LoadingService.hideLoading();
      })
    },
    removeNotificationId() {
      DataService.delete('auth/me/notifications', {
      }).then(() => {
        this.notification_id = null;
        NotificationService.add('success', 'Notifications disabled');
      }).catch((err) => {
        NotificationService.add('danger', err);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-dl-content>a {
  border: none;
  background: none;
}

.form-field {
  max-width: unset;
  width: unset;
  margin: unset;
  display: unset;
  position: relative;
}

form {
  display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>