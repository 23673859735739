<template>
  <div class="modal">
    <div class="modal-header">
      Matches
    </div>
    <div class="modal-content">
      <ModalLoading :class="{'fade-in': is_loading === true, 'fade-out': is_loading !== true}"/>
      <div class="match-component admin-match-component" v-for="(match, index) in matches" :key="index">
        <div class="match-teams">
          <div class="team-element">
            <div class="team-flag-name">
              <span>{{match.op_1.name}}</span> vs <span>{{match.op_2.name}}</span>
            </div>
            <div class="team-score">
              {{ $filters.matchDayFilter(match.match_start)}} {{ $filters.matchTimeFilter(match.match_start)}}
            </div>
          </div>
        </div>
        <button @click="deleteMatch(match, index)">Remove</button>
      </div>
      <div class="add-new-match">
        <h3>Add new match</h3>
        <hr />
        <div class="opponent-selection">
          <select v-model="new_match.op_1">
            <option selected>...</option>
            <option v-for="(team, index) in teams" :key="`op_1-${index}`">
              {{team.name}}
            </option>
          </select>
          VS
          <select v-model="new_match.op_2">
            <option selected>...</option>
            <option v-for="(team, index) in teams" :key="`op_2-${index}`">
              {{team.name}}
            </option>
          </select>
          <input v-model="new_match.match_start" type="datetime-local">
          <button @click="addNewMatch">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import ModalLoading from '@root/components/ModalLoading';
import DataService from '@root/services/Dataservice';
import LoadingService from '@root/generic/LoadingService';

export default {
  name: 'AdminMatches',
  components: {
    ModalLoading
  },
  data() {
    return {
      is_loading: false,
      matches: [],
      new_match: {
        match_start: '11.06.2021, 18:00'
      },
      teams: [],
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init() {
      this.fetchMatches();
      this.fetchTeams();
    },
    fetchMatches () {
      this.is_loading = true;
      DataService.get('admin/matches')
      .then((result) => {
        this.matches = result;
        this.is_loading = false;
      })
      .catch(() => {
        this.is_loading = false;
      })
    },
    fetchTeams () {
      if (this.teams.length > 0) {
        return;
      }
      LoadingService.showLoading();
      DataService.get('teams')
      .then((result) => {
        this.teams = result;
        LoadingService.hideLoading();
      })
      .catch(() => {
        LoadingService.hideLoading();
      })
    },
    addNewMatch() {
      LoadingService.showLoading();
      if (this.new_match.match_start != null) {
        console.log("New match start", this.new_match.match_start);
        this.new_match.match_start = Date.parse(this.new_match.match_start) - new Date().getTimezoneOffset() * 60 * 1000;
      }
      DataService.post('admin/matches', this.new_match)
      .then(() => {
        LoadingService.hideLoading();
        this.new_match = {};
        this.fetchMatches();
      })
      .catch(() => {
        LoadingService.hideLoading();
      })
    },
    deleteMatch (match, index) {
      if (window.confirm(`Deleting match between ${match.op_1.name} vs ${match.op_2.name}?`)) {
        LoadingService.showLoading();
        DataService.delete('admin/matches/' + match.match_id)
        .then(() => {
          this.matches.splice(index, 1);
        })
        .finally(() => {
          LoadingService.hideLoading();
        });
      }
    }
  }
}

</script>