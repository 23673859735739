<template>
  <div class="main">
    <Popup v-if="show_payment_reminder" :cookie_id="$store.state.payment_reminder_cookie_id"
      @close="show_payment_reminder = false">
      <PaymentReminder />
    </Popup>
    <ModalLoading :class="{'fade-in': is_loading === true, 'fade-out': is_loading !== true}"/>
    <div class="modal match-day" v-for="(date_group, date_group_key) in grouped_matches" :key="date_group_key" :class="{'today': date_group.today === true, 'past': date_group.past === true, 'upcoming': date_group.upcoming === true}">
      <div class="modal-header">
        {{ $filters.matchDayFilter(date_group.date) }}
      </div>
      <Match class="modal-content" v-for="(match, index) in date_group.matches" :match="match" :key="match.match_id" :index="index" :date_group_key="date_group_key" @refresh="refresh" :last_in_list="index+1 === date_group.matches.length"/>
    </div>
  </div>
</template>

<script>

import Popup from '@root/components/Popup';
import DataService from '@root/services/Dataservice';
import ModalLoading from '@root/components/ModalLoading';
import Match from '@root/components/Match';
import PaymentReminder from '@root/components/PaymentReminder';

import UserService from '@root/services/UserService';

export default {
  name: 'Matches',
  components: {
    Popup,
    PaymentReminder,
    Match,
    ModalLoading
  },
  data () {
    return {
      grouped_matches: {},
      is_loading: false,
      show_popup: false,
      show_payment_reminder: false,
    }
  },
  mounted () {
    this.init()
    if (this.$store.state.can_show_upsell && UserService.hasSeenNotification(this.$store.state.bonuscards_notif_cookie_id) === false) {
      this.show_popup = true;
    }
    if (this.$store.state.can_show_payment_reminder && UserService.hasSeenNotification(this.$store.state.payment_reminder_cookie_id) === false) {
      this.show_payment_reminder = true;
    }
  },
  methods: {
    init () {
      this.fetchMatches();
    },
    fetchMatches () {
      this.is_loading = true;
      DataService.get('matches')
      .then((result) => {
        let current_time = new Date().getTime();
        let todays_key = this.getDateKey(current_time);
        this.grouped_matches[todays_key] = {
          date: current_time,
          today: true,
          matches: []
        };
        let upcoming = [];
        let past = [];
        // Initial sorting
        for (const k in result) {
          if (result[k].match_start > current_time) {
            upcoming.push(result[k]);
          } else {
            past.push(result[k]);
          }
        }

        for (const k in upcoming) {
          const date_key = this.getDateKey(upcoming[k].match_start);
          if (this.grouped_matches[date_key] == null) {
            this.grouped_matches[date_key] = {
              date: upcoming[k].match_start,
              upcoming: true,
              matches: []
            };
          }
          this.grouped_matches[date_key].matches.push(upcoming[k]);
        }

        for (const k in past) {
          const date_key = this.getDateKey(past[k].match_start);
          if (this.grouped_matches[date_key] == null) {
            this.grouped_matches[date_key] = {
              date: past[k].match_start,
              past: true,
              matches: []
            };
          }
          this.grouped_matches[date_key].matches.push(past[k]);
        }


        if (this.grouped_matches[todays_key].matches.length < 1) {
          delete this.grouped_matches[todays_key];
        }
        this.is_loading = false;
      })
      .catch(() => {
        this.grouped_matches = {};
        this.is_loading = false;
      })
    },
    refresh (grouping_index, at_index, new_data) {
      
      // This one seems to refresh only one element as well - idk
      let groups = this.grouped_matches
      this.grouped_matches = {};
      groups[grouping_index].matches.splice(at_index, 1, new_data);
      this.grouped_matches = groups;

      // This one doesn't work
      // this.grouped_matches[grouping_index].matches.splice(at_index, 1, new_data);
    },
    getDateKey (ts) {
      const date = new Date(ts);
      return `_${date.getDate()}_${date.getMonth()}_${date.getFullYear()}`;
    }
  }
}
</script>