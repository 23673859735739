<template>
  <div class="modal">
    <div class="modal-header">
      Bonuses
    </div>
    <div class="modal-content">
      <strong>Your chance to get extra 20pt.</strong>
      <p>Place your bets before <strong>{{ $filters.longDateFilter(this.final) }}</strong>.<br>Selections are saved automatically and you can
        change them up to {{ $filters.longDateFilter(this.final) }}</p>
      <p>Time left: {{ left_hours }} Hours | {{left_minutes}} Minutes | {{left_seconds}} {{ seconds_txt }}</p>
      <div class="bonus-form-input">
        <h4>UEFA 2024 finalist (5pt)</h4>
        <label for="winner_select">One finalist:</label>
        <select v-model="finalist_1" @change="bonusChanged" id="finalist_1_select">
          <option v-for="(item, key) in not_selected_teams" :key="key" :value="item" :selected="finalist_1 == item">
            {{item}}
          </option>
        </select>
      </div>
      <div class="bonus-form-input">
        <h4>UEFA 2024 finalist (5pt)</h4>
        <label for="finalist_2_select">Another finalist:</label>
        <select v-model="finalist_2" @change="bonusChanged" id="finalist_2_select">
          <option v-for="(item, key) in not_selected_teams" :key="key" :value="item" :selected="finalist_2 == item">
            {{item}}
          </option>
        </select>
      </div>
      <div class="bonus-form-input">
        <h4>UEFA 2024 winner (10pt)</h4>
        <label for="winner_select">Winner:</label>
        <select v-model="winner" @change="bonusChanged" id="winner_select">
          <option v-for="(item, index) in user_finalists" :key="index" :value="item" :selected="winner == item">
            {{item}}
          </option>
        </select>
        <span class="error-message" v-if="winner_warning === true">Please select winner</span>
      </div>
    </div>
  </div>
</template>


<script>

import DataService from '@root/services/Dataservice';
// import UserService from '@root/services/UserService';
import LoadingService from '@root/generic/LoadingService';
import NotificationService from '../../generic/NotificationService';

export default {
  name: 'BonusBets',
  components: {

  },
  data () {
    return {
      bonusbets: {

      },
      teams : [
        'Germany',
        'Scotland',
        'Hungary',
        'Switzerland',
        'Spain',
        'Croatia',
        'Italy',
        'Albania',
        'Slovenia',
        'Denmark',
        'Serbia',
        'England',
        'Netherlands',
        'France',
        'Poland',
        'Austria',
        'Ukraine',
        'Slovakia',
        'Belgium',
        'Romania',
        'Portugal',
        'Czechia',
        'Georgia',
        'Türkiye',
      ],
      user_finalists: [],
      finalist_1: null,
      finalist_2: null,
      winner: null,
      left_hours: 0,
      left_minutes: 0,
      left_seconds: 0,
      final: this.$store.state.bonusbets_deadline,
      intervalCounter: null,
      winner_warning: false,
    }
  },
  computed: {
    seconds_txt () {
      return this.left_seconds === 1 ? 'second' : 'seconds';
    },
    not_selected_teams () {
      return this.teams;
    }
  },
  mounted () {
    this.startTimeCounter();
    this.fetchUserBonuses();
  },
  beforeUnmount () {
    if (this.intervalCounter != null) {
      clearInterval(this.intervalCounter);
      this.intervalCounter = null;
    }
  },
  methods : {
    fetchUserBonuses() {
      DataService.get('finals/my')
      .then((res) => {
        if (res.winner != null) {
          this.winner = res.winner;
        }
        if (res.finalist_1 != null) {
          this.finalist_1 = res.finalist_1;
          this.user_finalists.push(this.finalist_1);
        }
        if (res.finalist_2 != null) {
          this.finalist_2 = res.finalist_2;
          this.user_finalists.push(this.finalist_2);
        }
      })
      .catch(() => {
        // NotificationService.add('danger', err);
      })
    },
    bonusChanged() {
      this.user_finalists = [];
      if (this.finalist_1 != null) {
        this.user_finalists.push(this.finalist_1);
      }
      if (this.finalist_2 != null) {
        this.user_finalists.push(this.finalist_2);
      }

      if (this.user_finalists.indexOf(this.winner) < 0) {
        this.winner = null;
        this.winner_warning = true;
      } else {
        this.winner_warning = false;
      }

      // Update data in DB
      LoadingService.showLoading();
      DataService.post('finals', {
        finalist_1: this.finalist_1 || null,
        finalist_2: this.finalist_2 || null,
        winner: this.winner || null
      }).then(() => {
        NotificationService.add('success', 'Bonus updated');
      }).catch((err) => {
        NotificationService.add('danger', err);
      }).finally(() => {
        LoadingService.hideLoading();
      })
    },
    startTimeCounter () {
      this.intervalCounter = setInterval(() => {
        let current = new Date().getTime();
        let totalseconds = (this.final - current) / 1000;
        this.left_hours = Math.floor(totalseconds / 3600);
        this.left_minutes = Math.floor((totalseconds % 3600) / 60);
        this.left_seconds = Math.floor((totalseconds % 60));

      }, 100);
    }
  }
}

</script>