<template>
  <div class="bet-modal">
    <div class="bet-container">
      {{ message }}
      <slot></slot>
      <div class="modal-footer">
        <!-- <button class="cancel" @click="modalNO"> Cancel </button> -->
        <button @click="closePopup"> OK </button>
      </div>
    </div>
  </div>
</template>

<script>

import Cookies from "@root/services/Cookieservice";

export default {
  name: 'Popup',
  props: {
    cookie_id: String,
    message: String
  },
  data () {
    return {
      // modal_message
    }
  },
  methods: {
    closePopup () {
      Cookies.set(this.cookie_id, true);
      this.$emit('close');
    },
  }
}
</script>