<template>
  <div class="main">
    <div class="modal">
      <div class="modal-header">
        Teams
      </div>
      <div class="modal-content">
        <ModalLoading :class="{'fade-in': is_loading === true, 'fade-out': is_loading !== true}" />
        <div class="match-day" v-for="(group, group_index) in teams" :key="group_index">
          <div class="match-day-date teams">
            <span>Group</span> {{ group_index }}
          </div>
          <div class="flex-table team">
            <div class="flex-row">
            </div>
            <div class="flex-row header">
              <div class="flex-col header">
              </div>
              <div class="flex-col grow-3 header">
                Team
              </div>
              <div class="flex-col grow-1 header">
                Played
              </div>
              <div class="flex-col grow-1 header">
                Won
              </div>
              <div class="flex-col grow-1 header">
                Drawn
              </div>
              <div class="flex-col grow-1 header">
                Lost
              </div>
              <div class="flex-col grow-1 header">
                For
              </div>
              <div class="flex-col grow-1 header">
                Against
              </div>
              <div class="flex-col grow-1 header">
                Goal difference
              </div>
              <div class="flex-col grow-1 header">
                Points
              </div>
            </div>
            <div class="flex-row" v-for="(team, team_index) in group" :key="team_index">
              <div class="flex-col team-image">
                <img :src="team.img" />
              </div>
              <div class="flex-col grow-3 team-name">
                {{team.name}}
              </div>
              <div class="flex-col grow-1">{{ team.stats.played}}</div>
              <div class="flex-col grow-1">{{ team.stats.won}}</div>
              <div class="flex-col grow-1">{{ team.stats.drawn}}</div>
              <div class="flex-col grow-1">{{ team.stats.lost}}</div>
              <div class="flex-col grow-1">{{ team.stats.goalsFor}}</div>
              <div class="flex-col grow-1">{{ team.stats.goalsAgainst}}</div>
              <div class="flex-col grow-1">{{ team.stats.goalDifference}}</div>
              <div class="flex-col grow-1 strong">{{ team.stats.points}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


// import ModalLoading from '@root/components/ModalLoading';
import DataService from '@root/services/Dataservice';
// import TeamStats from '@root/components/TeamStats';

export default {
  name: 'Teams',
  components: {
    // ModalLoading
    // TeamStats
  },
  data () {
    return {
      teams: [],
      is_loading: false
    }
  },
  mounted () {
    this.fetchTeams();
  },
  methods: {
    fetchTeams () {
      this.is_loading = true;
      DataService.get('teams?sortByGroups')
      .then((result) => {
        this.teams = result;
        this.is_loading = false;
      })
      .catch(() => {
        this.is_loading = false;
      })
    }
  }
}
</script>