<template>
  <div class="userstats">
    <div class="username">{{username}}</div>
  </div>
</template>

<script>

import UserService from '@root/services/UserService';

export default {
  name: 'UserStats',
  data () {
    return {
      user: null,
      username: null
    }
  },
  mounted() {
    UserService.subscribe((user) => {
      this.user = user;
      this.username = this.user.username;
    }, this);
  },
  methods: {
    
  }
}
</script>