<template>
  <div class="table-list">
    <template v-if="isDataEmpty">
      <div class="scoreboard-data-empty-notification">
        ...
      </div>
    </template>
    <template v-else>
      <button v-if="refreshable" class="scoreboard-refresh" @click="refreshTable">Refresh table</button>
      <button v-if="filterable" class="scoreboard-filter-btn" @click="toggleFilter">Filter All/My group</button>
      <p class="scoreboard-filter-info">Filtering by: {{ local_filter_all === true ? 'All' : 'My group' }}</p>
      <ModalLoading v-if="has_loading === true"
        :class="{'fade-in': is_loading === true, 'fade-out': is_loading !== true}" />
      <div class="flex-table" :class="{'loaded-successfully': was_load_success === true}">
        <div class="flex-row">
          <div class="flex-col header" v-for="header in items.headers" :key="header.key"
            :class="(header.extra_classes != null ? header.extra_classes: '')">
            {{header.title}}
          </div>
        </div>
        <div
          class="flex-row"
          v-for="(item, index) in filtered_items.data"
          :key="index"
          :class="{
            'highlighted': item.highlight === true,
            'in-same-group': item.in_same_group === true
          }"
          @mouseenter="toggleHighlighting"
          @mouseleave="toggleHighlighting">
          <div class="flex-col" v-for="(value, key, col_index) in getCleanedItem(item)" :key="`${index}-${key}`"
            :class="getExtraClasses(col_index)">
            <template v-if="key === 'points'">
              {{value}}<span v-if="value - top_points < 0" class="points-diff">{{value - top_points}}</span>
            </template>
            <template v-else>
              {{value}}
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import ModalLoading from '@root/components/ModalLoading';
import { clone } from '@root/helpers/Global';

export default {
  name: 'ScoreBoard',
  components: {
    ModalLoading,
  },
  props: {
    items: Object,
    has_loading: {
      type: Boolean,
      default: true
    },
    refreshable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filter_all: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDataEmpty () {
      return Object.keys(this.items).length < 1;
    },
    filtered_items () {
      return this.filterItems(this.items);
    }
  },
  data () {
    return {
      is_loading: false,
      was_load_success: false,
      top_points: 0,
      local_filter_all: false
    }
  },
  mounted() {
    this.is_loading = true;
    this.top_points = null;
    this.local_filter_all = this.filter_all;
  },
  methods: {
    tableReloaded () {
      // this.items = data;
      this.is_loading = false;
      this.was_load_success = true;
      this.top_points = null;
    },
    getCleanedItem (data) {
      data = clone(data);
      for (const key in data) {
        let value = data[key];
        if (key === 'highlight') {
          delete data[key];
        } else if (key === 'points') {
          if (this.top_points == null) {
            this.top_points = value;
          }
        } else if (key === 'in_same_group') {
          delete data[key];
        }
      }
      return data;
    },
    refreshTable () {
      this.is_loading = true;
      this.was_load_success = false;
      // Await here so is_loading won't be triggered before actual request has completed -- OLD
      this.$emit('refresh');
      // this.is_loading = false;
      // this.was_load_success = true;
    },
    getExtraClasses(col_index) {
      if (col_index != null && this.items != null && this.items.headers[col_index] != null && this.items.headers[col_index].extra_classes != null) {
        return this.items.headers[col_index].extra_classes;
      }
      return '';
    },
    toggleHighlighting (el) {
      el.target.classList.toggle('highlighted-hover');
    },
    toggleFilter () {
      this.local_filter_all = !this.local_filter_all;
    },
    filterItems(data) {
      if (this.local_filter_all === true) {
        return data;
      } else {
        let new_data = clone(data);
        new_data.data = new_data.data.filter((item) => {
          return item.in_same_group === true;
        });
        // Change new_data rank numbers
        for (let i = 0; i < new_data.data.length; i++) {
          new_data.data[i].rank = i + 1;
        }
        return new_data;
      }
    }
  },
  watch: {
    items: {
      handler (data) {
        this.tableReloaded(data);
      }
    }
  }
}

</script>