<template>
  <div id="app">
    <n-notification-provider placement="bottom-right">
      <Loading />
      <Header ref="header_component" />
      <div class="page-container">
        <!-- <h3 style="text-align: center">Toimub uuendamine...<br>Tagasi online kell 22:45</h3> -->
        <router-view></router-view>
      </div>
      <Notifications/>
    </n-notification-provider>
  </div>
</template>

<script>

import Loading from '@root/generic/Loading.vue'
import { NNotificationProvider } from 'naive-ui'
// import LoadingService from '@root/generic/LoadingService'
import Header from '@root/components/Header.vue'
import Notifications from '@root/generic/Notifications.vue'



export default {
  name: 'App',
  components: {
    Loading,
    Header,
    Notifications,
    NNotificationProvider
  },
  data() {
    return {
    }
  }
}
</script>
