import { createRouter, createWebHistory } from 'vue-router'
import UserService from '@root/services/UserService';

import Home from '@root/pages/Home.vue';
import Matches from '@root/pages/Matches.vue';
import Teams from '@root/pages/Teams.vue';
import Scoreboard from '@root/pages/Scoreboard.vue';
import NotificationsV2 from '@root/pages/NotificationsV2.vue';
import Admin from '@root/pages/Admin.vue';
import Login from '@root/views/Login.vue';
import Register from '@root/views/Register.vue';
import PasswordReset from '@root/views/PasswordReset.vue';

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/matches',
    name: 'matches',
    component: Matches,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/teams',
    name: 'teams',
    component: Teams,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/scoreboard',
    name: 'scoreboard',
    component: Scoreboard,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsV2,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  }, {
    path: '/login',
    component: Login
  }, {
    path: '/register',
    component: Register
  }, {
    path: '/passreset',
    component: PasswordReset
  }, {
    path: '/:pathMatch(.*)*',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // console.log('Router before each');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    UserService.isAuthorized().then((_authed) => {
      // console.log('isauthed', _authed);
      if (_authed === true) {
        if (to.matched.some(record => record.meta.adminOnly)) {
          if (UserService.isAdmin()) {
            next();
          } else {
            next(false);
          }
        } else {
          next();
        }
      } else {
        next({
          path: '/login'
        })
      }
    });
  } else {
    UserService.isAuthorized().then((_authed) => {
      if (_authed === true) {
        next({
          path: '/home'
        });
      } else {
        next();
      }
    });
  }
});

export default router
